'use client';
import * as React from 'react';

import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import { usePathname } from 'next/navigation';
import { useLocale } from 'next-intl';

import BaseCrumb from './BaseCrumb';
import { createBreadcrumbs } from './createBreadcrumb';
import GenericCrumb from './GenericCrumb';
import HomeCrumb from './HomeCrumb';
import { Wrapper } from './index.styled';
import Icon from '@/ui/components/Icon';

const Separator = () => <Icon name="chevron-right" size="sm" />;

export type Crumb = {
  name: string;
  url?: string;
};

export type ItemType = (Crumb | RestDataPayload)[];

interface BreadcrumbsProps {
  base?: string;
  items: ItemType;
}

/**
 * @description Breadcrumbs navigation
 * @todo auto home and support
 * @todo mobile
 * @todo breakpoint from theme
 * @todo translate
 * @todo translate aria
 * @example <Breadcrumbs items={[{ name: 'Home', url: '/', categoryData, articleData }]} />
 */
export default function Breadcrumbs({ base, items }: BreadcrumbsProps) {
  const pathname = usePathname();
  const locale = useLocale();

  const processedItems = React.useMemo(() => {
    return createBreadcrumbs(items, locale);
  }, [items]);

  return (
    <Wrapper>
      <MuiBreadcrumbs
        separator={<Separator />}
        aria-label="breadcrumb"
        sx={{
          width: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          flexWrap: 'nowrap',
        }}
      >
        <HomeCrumb />
        {base && <BaseCrumb name={base} currentPath={pathname} />}
        {processedItems &&
          processedItems.map((crumb, index) => (
            <GenericCrumb key={index} item={crumb} currentPath={pathname} />
          ))}
      </MuiBreadcrumbs>
    </Wrapper>
  );
}
