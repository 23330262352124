'use client';
import React from 'react';

import { useTranslations } from 'next-intl';

import Breadcrumbs, { ItemType } from '@/ui/components/Breadcrumbs';

interface BreadcrumbsProps {
  items: ItemType;
}

export default function BreadcrumbsWrap({ items }: BreadcrumbsProps) {
  const translate = useTranslations();
  return <Breadcrumbs base={translate('breadcrumb-support')} items={items} />;
}
