'use client';

import { Grid } from '@mui/material';
import { useTranslations, useLocale } from 'next-intl';
import styled from 'styled-components';

import { LINKS } from './constants';
import ImageCard from '@/ui/components/Card/forGrid/ImageCard';
import { addLocalePath } from '@/utils/urlBuilder/utils';
// interface ProcuctsListProps {
//   linkToSlicer: string;
// }

const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 70px;
  max-width: 980px;
  overflow: hidden;
`;

/**
 * @todo link from /info endpoint linkToSlicer ProcuctsListProps
 */
const FeaturedLinks = () => {
  const locale = useLocale();
  const t = useTranslations();

  const translatedLinks = LINKS.map(link => ({
    ...link,
    title: link.titleKey ? t(link.titleKey) : link.title,
    linkUrl: addLocalePath(locale, link.linkUrl),
  }));

  return (
    <Wrapper>
      <Grid
        container
        columns={{ xs: 16, md: 8 }}
        spacing={4}
        justifyContent={'space-evenly'}
        alignItems="stretch"
      >
        {translatedLinks.map(link => (
          <ImageCard key={link.id} item={link} />
        ))}
      </Grid>
    </Wrapper>
  );
};

export default FeaturedLinks;
