import { ItemType } from './';
import { POST_TYPES } from '@/constants';
import { Crumb } from '@/ui/components/Breadcrumbs';
import { log } from '@/utils/logger';
import { urlBuilder } from '@/utils/urlBuilder';

type CreateBreadcrumbsType = ItemType;

const transformPostTypeToCrumb = (crumbs, locale): Crumb[] => {
  const transformedCrumbs = crumbs.map(crumb => {
    if (!crumb) {
      log.error('createBreadcrumbs: crumb is empty');
      return null;
    }

    if (
      typeof crumb === 'object' &&
      'type' in crumb &&
      POST_TYPES.includes(crumb.type)
    ) {
      return {
        name: crumb.title,
        url: urlBuilder({ item: crumb, locale }),
      };
    }

    if (typeof crumb === 'object' && 'name' in crumb) {
      return {
        name: crumb.name,
        url: crumb.url || null,
      };
    }

    log.error('createBreadcrumbs: crumb is missing name or url');
    return null;
  });

  return transformedCrumbs;
};

/**
 * @description Create breadcrumbs from array of crumbs or post type items
 * @example createBreadcrumbs([{ name: 'Home', url: '/' }, categoryData, acticleData])
 */
export function createBreadcrumbs(
  crumbs: CreateBreadcrumbsType,
  locale: string
): Crumb[] | null {
  if (!Array.isArray(crumbs)) {
    log.error('createBreadcrumbs: crumbs is not an array');
    return [];
  }
  const postTypeToCrumb = transformPostTypeToCrumb(crumbs, locale);

  const validatedCrumbs = postTypeToCrumb.filter(crumb => {
    if (!crumb?.name) {
      log.error('createBreadcrumbs: crumb is missing name');
      return false;
    }
    return crumb;
  });

  const filteredCrumbs = validatedCrumbs.filter(crumb => crumb);

  if (filteredCrumbs.length === 0) {
    // log.error('createBreadcrumbs: no crumbs found');
    return null;
  }

  return filteredCrumbs;
}
