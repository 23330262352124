import React from 'react';

import CrumbLink from './CrumbLink';
import Icon from '@/ui/components/Icon';

/**
 * @todo url to ENV
 */
export default function HomeBreadcrumb() {
  return (
    <CrumbLink href="https://www.prusa3d.com/">
      <Icon name="house" size="xl" />
    </CrumbLink>
  );
}
