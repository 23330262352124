'use client';

import { GrayStrip } from './layout.styled';
import BreadcrumbsWrap from '@/ui/components/BreadcrumbsWrap';
import FeaturedLinks from '@/ui/containers/homePage/FeaturedLinks';
import SearchSection from '@/ui/containers/homePage/SearchSection';
import Footer from '@/ui/containers/layout/footer';
import Header from '@/ui/containers/layout/header';
import LayoutContainer from '@/ui/containers/layout/LayoutContainer';

export default function LayoutWithHeaderAndFooter({ children, forum }) {
  return (
    <>
      <Header />
      <LayoutContainer>
        <BreadcrumbsWrap items={[]} />
        <SearchSection />
        <GrayStrip>{children}</GrayStrip>
        <FeaturedLinks />
        {forum}
      </LayoutContainer>
      <Footer />
    </>
  );
}
