import styled from 'styled-components';

export const GrayStrip = styled.div`
  background: ${props => props.theme.palette.gray.light};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
  padding: 2.5rem 2.8125rem;
  gap: 2.5rem;

  margin-left: -45px;
  margin-right: -45px;

  ${({ theme }) => theme.breakpoints.down(1390)} {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100% + 40px);
  }

  img {
    mix-blend-mode: multiply;
  }
`;
