'use client';

// Static non-translated links
export const LINKS = [
  {
    id: 'filament-material-guide',
    titleKey: 'materialGuide-title',
    linkUrl: '/filament-material-guide',
    media: {
      image: '/visuals/images/featured-links/filament-material-guide.png',
    },
  },
  {
    id: 'prusa-slicer',
    title: 'PrusaSlicer',
    linkUrl: '/article/general-info_1910',
    type: 'helps',
    slug: 'general-info_1910',
    media: {
      image: '/visuals/images/featured-links/slicer.png',
    },
  },
  {
    id: 'prusa-connect',
    title: 'Prusa Connect',
    linkUrl: '/article/prusa-connect-and-prusalink-explained_302608',
    type: 'helps',
    slug: 'prusa-connect-and-prusalink-explained_302608',
    media: {
      image: '/visuals/images/featured-links/prusa-connect.png',
    },
  },
  {
    id: 'firmware-downloads',
    titleKey: 'downloads-title',
    linkUrl: '/downloads',
    media: {
      image: '/visuals/images/featured-links/downloads.png',
    },
  },
];
