import styled from 'styled-components';

export const Wrapper = styled.div`
  color: ${({ theme }) => theme.palette.text.disabled};
  margin: 1rem 0;
  flex-shrink: 1;
  flex-grow: 0;

  ol {
    font-size: 12px;
    line-height: 14px;
    flex-wrap: nowrap;
    overflow: hidden;
  }

  li {
    margin-bottom: 0;
  }

  a {
    color: ${({ theme }) => theme.palette.text.disabled};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
      text-decoration: none;
    }
  }

  strong {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;
