import Link from 'next/link';
import { useLocale } from 'next-intl';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.custom.colors.primary};

  &:hover {
    text-decoration: underline !important;
  }
`;

interface CrumbLinkProps {
  href: string;
  children: React.ReactNode;
}

export default function CrumbLink({ href, children }: CrumbLinkProps) {
  const locale = useLocale();

  return (
    <StyledLink href={href} locale={locale}>
      {children}
    </StyledLink>
  );
}
