import React from 'react';

import { useLocale } from 'next-intl';

import Crumb from './Crumb';
import { LOCALES } from '@/i18n';

/**
 * @todo translate
 */
export default function BaseCrumb({ name, currentPath }) {
  const locale = useLocale();

  // Check if the current path is the homepage or any language-specific homepage
  const isHomepage =
    currentPath === '/' || LOCALES.some(locale => currentPath === `/${locale}`);

  const crumb = {
    ...(name ? { name } : {}),
    ...(isHomepage ? {} : { url: `/${locale}` }),
  };

  return <Crumb currentPath={currentPath} item={crumb} />;
}
