'use client';

import React from 'react';

import { useLocale, useTranslations } from 'next-intl';
import styled from 'styled-components';

import SearchInput from '@/ui/components/SearchInput';

/**
 * @todo theme color
 */
const OrangeStrip = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  margin-left: -45px;
  margin-right: -45px;
  padding: 1.5rem 45px;

  ${({ theme }) => theme.breakpoints.down(1390)} {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100% + 40px);
  }
`;

const SearchSection = () => {
  const translate = useTranslations();
  const locale = useLocale();

  return (
    <OrangeStrip>
      <SearchInput
        frontpagePlaceholder={translate('homepage-search')}
        locale={locale}
      />
    </OrangeStrip>
  );
};

export default SearchSection;
